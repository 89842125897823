import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/personal-site/personal-site/src/components/Article.js";
import CodeSandbox from "components/CodeSandbox";
import Repl from "components/Repl";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I see a lot of people, especially those new to React hooks, shy away from
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#usereducer"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`useReducer`}</code></a>{` in favor of multiple `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{`s. I think some of it
is the simplicity of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{` API, and some of it is assumed complexity
via association with `}<a parentName="p" {...{
        "href": "https://redux.js.org"
      }}>{`Redux`}</a>{`. In practice, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useReducer`}</code>{` has a lot more in
common with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{` than Redux.`}</p>
    <h2 {...{
      "id": "table-of-contents",
      "style": {
        "position": "relative"
      }
    }}>{`Table of Contents`}<a parentName="h2" {...{
        "href": "#table-of-contents",
        "aria-label": "table of contents permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#updating-state-with-usestate-and-usereducer"
        }}>{`Updating State With `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`useState`}</code>{` and `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`useReducer`}</code></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-reducer-function"
        }}>{`The Reducer Function`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#putting-it-all-together-with-usereducer"
        }}>{`Putting It All Together With useReducer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#reducer-without-any-params"
        }}>{`Reducer Without Any Params`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#reducer-using-current-state"
        }}>{`Reducer Using Current State`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#takeaways"
        }}>{`Takeaways`}</a></li>
    </ul>
    <undefined></undefined>
    <h2 {...{
      "id": "updating-state-with-usestate-and-usereducer",
      "style": {
        "position": "relative"
      }
    }}>{`Updating State With `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`useState`}</code>{` and `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`useReducer`}</code><a parentName="h2" {...{
        "href": "#updating-state-with-usestate-and-usereducer",
        "aria-label": "updating state with usestate and usereducer permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{` API has two parts: the state and the state setter. When you want
to update the state, you replace it using the state setter.`}</p>
    <p><img parentName="p" {...{
        "src": "/1c3fbedf231017327a3616382f8f3fe9/setState-flow.svg",
        "alt": "Sequence diagram of React.useState setState flow"
      }}></img></p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useReducer`}</code>{` API has three parts: the state, an action dispatcher, and a
reducer to produce the new state.`}</p>
    <p><img parentName="p" {...{
        "src": "/21180075d75a6e961abf29165b772f99/dispatch-flow.svg",
        "alt": "Sequence diagram of React.useReducer dispatch flow"
      }}></img></p>
    <p>{`When you want to update the state:`}</p>
    <ol>
      <li parentName="ol">{`Dispatch an action.`}</li>
      <li parentName="ol">{`The hook passes the current state and the action to the reducer function.`}</li>
      <li parentName="ol">{`The reducer computes a new state.`}</li>
      <li parentName="ol">{`The state is updated with the new state.`}</li>
    </ol>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`useReducer`}</code>{` takes care of steps 2 and 4. You’re responsible for steps 1 and 3.`}</p>
    <h2 {...{
      "id": "the-reducer-function",
      "style": {
        "position": "relative"
      }
    }}>{`The Reducer Function`}<a parentName="h2" {...{
        "href": "#the-reducer-function",
        "aria-label": "the reducer function permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`These are all valid signatures for reducer functions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`currentState`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` dispachedAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`newState`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`currentState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`newState`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` newState`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`As you can see, `}<mark>{`the primary job of a reducer is to produce a new
state`}</mark>{`, optionally taking into account the current state and the
dispatched action.`}</p>
    <h2 {...{
      "id": "putting-it-all-together-with-usereducer",
      "style": {
        "position": "relative"
      }
    }}>{`Putting It All Together With useReducer`}<a parentName="h2" {...{
        "href": "#putting-it-all-together-with-usereducer",
        "aria-label": "putting it all together with usereducer permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#usereducer"
      }}>{`The signature of `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`useReducer`}</code></a>{` is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` dispatch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useReducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reducer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` initialState`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span></span></pre></div>
    <p>{`You pass in a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` function (and optionally an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`initialState`}</code>{`) to
instantiate a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useReducer`}</code>{` hook. What you get back is a `}<strong parentName="p">{`read-only`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`state`}</code>{`
and a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch`}</code>{` function which you’ll use to trigger the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{`.`}</p>
    <p>{`This is one of the key differences when compared to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{`—a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useReducer`}</code>{`
hook computes the next state internally using a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` function, while a
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{` hook relies entirely on external calculations for its next state.`}</p>
    <p>{`For the rest of the article, we’ll focus on different `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` (a.k.a. “new
state” calculator) implementation patterns.`}</p>
    <h2 {...{
      "id": "reducer-without-any-params",
      "style": {
        "position": "relative"
      }
    }}>{`Reducer Without Any Params`}<a parentName="h2" {...{
        "href": "#reducer-without-any-params",
        "aria-label": "reducer without any params permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`() => newState`}</code></p>
    <p>{`The primary job of a reducer is to return a new state. In its simplest form, a
reducer doesn’t even need to accept any parameters.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`reducer`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Date`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`date`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` update`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`useReducer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Date`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      Now`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`date`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`toLocaleDateString`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`date`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`toLocaleTimeString`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`button onClick`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`update`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{`Update`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`button`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`The names `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`state`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` are conceptual. In practice, you
can name them anything you like. In this example, they are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`date`}</code>{` (state),
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`update`}</code>{` (dispatch), and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{`.`}</p>
    <p>{`Notice that we invoke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`update()`}</code>{` `}<em parentName="p">{`without`}</em>{` passing in any parameters. The
dispatch function signals the hook to run the reducer function, it’s up to you
whether you need to send an action with the signal or not. In this case, the
hook actually runs `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer(currentState, undefined)`}</code>{`.`}</p>
    <h3 {...{
      "id": "-but-wait-the-reducer-doesnt-accept-any-params",
      "style": {
        "position": "relative"
      }
    }}>{`🤔 But Wait, the Reducer Doesn’t Accept Any Params`}<a parentName="h3" {...{
        "href": "#-but-wait-the-reducer-doesnt-accept-any-params",
        "aria-label": " but wait the reducer doesnt accept any params permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`That’s right. In JavaScript, just because you pass parameters to a function,
doesn’t mean it needs to accept them.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`sayHi`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hi"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sayHi`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// result === "hi"`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <Repl repl="reducer-pattern-00" header="" title="function params" style={{
      maxHeight: 300
    }} mdxType="Repl" />
    <CodeSandbox as="h3" sandbox="reducer-pattern-00-zuet6" header="👩‍💻 Try the No-Params Reducer Example" title="no-params reducer" style={{
      maxHeight: 375
    }} mdxType="CodeSandbox" />
    <h2 {...{
      "id": "reducer-using-current-state",
      "style": {
        "position": "relative"
      }
    }}>{`Reducer Using Current State`}<a parentName="h2" {...{
        "href": "#reducer-using-current-state",
        "aria-label": "reducer using current state permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`(currentState) => newState`}</code></p>
    <p>{`This form of reducer is useful when you need the current state to calculate the
next state.`}</p>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-js line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token function-variable function"
            }}>{`reducer`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`count`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` count `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`+`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` addOne`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` React`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`useReducer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`reducer`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`div`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`count`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`div`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`button onClick`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`addOne`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{`Add `}<span parentName="span" {...{
              "className": "token number"
            }}>{`1`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`button`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`main`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Once again, the dispatch function, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`addOne()`}</code>{`, doesn’t need to pass an action to
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{`—the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reducer`}</code>{` is capable of computing the next state entirely based on
the current state.`}</p>
    <CodeSandbox as="h3" sandbox="reducer-pattern-01-gmuic" title="current state reducer" style={{
      maxHeight: 375
    }} mdxType="CodeSandbox" />
    <h2 {...{
      "id": "takeaways",
      "style": {
        "position": "relative"
      }
    }}>{`Takeaways`}<a parentName="h2" {...{
        "href": "#takeaways",
        "aria-label": "takeaways permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`The primary job of a reducer is to produce a new state.`}</li>
      <li parentName="ul">{`A reducer function can take zero, one, or two params:
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`([currentState], [dispachedAction])`}</code>{`.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`dispatch()`}</code>{` signals the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`useReducer`}</code>{` hook to invoke the reducer.`}</li>
    </ul>
    <h2 {...{
      "id": "intermission",
      "style": {
        "position": "relative"
      }
    }}>{`Intermission`}<a parentName="h2" {...{
        "href": "#intermission",
        "aria-label": "intermission permalink",
        "className": "header-link after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`😅 Whew, let’s take a break for now. `}<a parentName="p" {...{
        "href": "/articles/reducer-patterns/part-2/"
      }}>{`In part 2`}</a>{`: we’ll explore reducers
that use actions, as well as actions in combination with the current state.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/reducer-patterns/part-2/"
        }}>{`Part 2: Reducers with actions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/reducer-patterns/part-3/"
        }}>{`Part 3: Reducers with switch statements`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      